import {
  Accordion,
  Accordions,
  AccountAddressDetails,
  AccountDetails,
  AccountOrderList,
  AccountProductList,
  BlogPostCard,
  CalendlyWidget,
  ClickUpDynamicParameter,
  CollectionCarousel,
  ContactForm,
  ContentCarousel,
  CookieDeclaration,
  DemoForm,
  Freeform,
  Iframe,
  LoginForm,
  Newsletter,
  PasswordRecoveryForm,
  PredefinedContentBlock,
  ProductBundleForm,
  ProductCard,
  QuoteCarousel,
  SignupForm,
  TeamMemberCard,
  Video,
} from '@data/sanity/schema'
import { SanityBlogPostWithoutBody, SanityBlogPostOptions } from './blog'
import { SanityContentFragment } from './content'
import { SanityImageFragment } from './image'
import { SanityProductBundle, SanityProductFragment } from './product'
import { HasAccountStrings, HasAuthStrings } from './site'
import { SanityVideo } from './video'
import { HasFontCase, SanityButtonStyle } from './link'

export enum SanityBlockType {
  ACCORDIONS = 'accordions',
  ACCOUNT_ADDRESS_DETAILS = 'accountAddressDetails',
  ACCOUNT_DETAILS = 'accountDetails',
  ACCOUNT_ORDER_LIST = 'accountOrderList',
  ACCOUNT_PRODUCT_LIST = 'accountProductList',
  BLOG_POST_CARD = 'blogPostCard',
  CALENDLY_WIDGET = 'calendlyWidget',
  COLLECTION_CAROUSEL = 'collectionCarousel',
  CONTACT_FORM = 'contactForm',
  CONTENT_CAROUSEL = 'contentCarousel',
  COOKIE_DECLARATION = 'cookieDeclaration',
  DEMO_FORM = 'demoForm',
  FREEFORM = 'freeform',
  IFRAME = 'iframe',
  LOGIN_FORM = 'loginForm',
  NEWSLETTER = 'newsletter',
  PASSWORD_RECOVERY_FORM = 'passwordRecoveryForm',
  PREDEFINED_CONTENT_BLOCK = 'predefinedContentBlock',
  PRODUCT_BUNDLE_FORM = 'productBundleForm',
  PRODUCT_CARD = 'productCard',
  QUOTE_CAROUSEL = 'quoteCarousel',
  SIGNUP_FORM = 'signupForm',
  TEAM_MEMBER_CARD = 'teamMemberCard',
  VIDEO = 'video',
}

export type SanityFreeformBlock = Pick<
  Freeform,
  '_type' | 'textAlign' | 'maxWidth'
> & {
  _key: string
  content: SanityContentFragment[]
}

export type SanityFreeform = Omit<SanityFreeformBlock, '_type' | '_key'>

export type SanityAccordionsBlockItem = Pick<Accordion, 'title'> & {
  id: string
  content: SanityContentFragment[]
}

export type SanityAccordionsBlock = Pick<Accordions, '_type'> & {
  _key: string
  items: SanityAccordionsBlockItem[]
}

export type SanityAccordions = Omit<SanityAccordionsBlock, '_type' | '_key'>

export type SanityProductCardBlock = Pick<ProductCard, '_type'> & {
  _key: string
  product: SanityProductFragment
}

export type SanityProductCard = Omit<SanityProductCardBlock, '_type' | '_key'>

export type SanitySignupFormBlock = SignupForm &
  HasAuthStrings & {
    _key: string
  }

export type SanityLoginFormBlock = LoginForm &
  HasAuthStrings & {
    _key: string
  }

export type SanityPasswordRecoveryFormBlock = PasswordRecoveryForm &
  HasAuthStrings & {
    _key: string
  }

export type SanityAccountDetailsBlock = AccountDetails &
  HasAccountStrings & {
    _key: string
  }

export type SanityAccountAddressDetailsBlock = AccountAddressDetails &
  HasAccountStrings & {
    _key: string
  }

export type SanityAccountProductListBlock = AccountProductList &
  HasAccountStrings & {
    _key: string
  }

export type SanityAccountOrderListBlock = AccountOrderList &
  HasAccountStrings & {
    _key: string
  }

export type SanityVideoBlock = Pick<Video, '_type'> &
  SanityVideo & {
    _key: string
  }

export type SanityCollectionCarouselBlock = Pick<
  CollectionCarousel,
  '_type'
> & {
  _key: string
  collection: {
    products: SanityProductFragment[]
  }
}

export type SanityCollectionCarousel = Omit<
  SanityCollectionCarouselBlock,
  '_type' | '_key'
>

export type SanityNewsletterBlock = Pick<
  Newsletter,
  '_type' | 'klaviyoListID' | 'hubSpotFormId' | 'submit'
> & {
  _key: string
  service: 'klaviyo' | 'hubSpot'
  variant?: 'basic' | 'extended'
  title?: string
  description?: string
  successMsg: SanityContentFragment[]
  errorMsg: SanityContentFragment[]
  terms?: SanityContentFragment[]
}

export type SanityProductBundleFormBlock = Pick<ProductBundleForm, '_type'> & {
  _key: string
  productBundle: SanityProductBundle
  showGallery?: boolean
}

export type SanityBlogPostCardBlock = Pick<BlogPostCard, '_type'> & {
  _key: string
  post: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

export enum SanityContentCarouselItemType {
  FREEFORM = 'freeform',
  PHOTO = 'photo',
  PRODUCT = 'product',
  BLOG_POST = 'blogPost',
  VIDEO = 'video',
}

type SanityContentCarouselFreeformItem = {
  _type: SanityContentCarouselItemType.FREEFORM
  freeform: SanityFreeformBlock
}

type SanityContentCarouselPhotoItem = {
  _type: SanityContentCarouselItemType.PHOTO
  photo: SanityImageFragment
}

type SanityContentCarouselProductItem = {
  _type: SanityContentCarouselItemType.PRODUCT
  product: SanityProductFragment
}

type SanityContentCarouselBlogPostItem = {
  _type: SanityContentCarouselItemType.BLOG_POST
  blogPost: SanityBlogPostWithoutBody
}

type SanityContentCarouselVideoItem = {
  _type: SanityContentCarouselItemType.VIDEO
  video: SanityVideo
}

export type SanityContentCarouselItem =
  | (SanityContentCarouselFreeformItem & {
      _key: string
    })
  | (SanityContentCarouselPhotoItem & {
      _key: string
    })
  | (SanityContentCarouselProductItem & {
      _key: string
    })
  | (SanityContentCarouselBlogPostItem & {
      _key: string
    })
  | (SanityContentCarouselVideoItem & {
      _key: string
    })

export interface SanityContentCarousel {
  items: SanityContentCarouselItem[]
}

export type SanityContentCarouselBlock = Pick<ContentCarousel, '_type'> &
  SanityContentCarousel & {
    _key: string
  }

export type SanityCalendlyWidgetBlock = Pick<CalendlyWidget, '_type'> & {
  _key: string
  url: string
  hideEventDetails?: boolean
  hideCookieSettings?: boolean
}

export type SanityCookieDeclarationBlock = Pick<
  CookieDeclaration,
  '_type' | 'active'
> & {
  _key: string
  cookieBotId: string
}

export type SanityDemoFormBlock = Pick<
  DemoForm,
  | '_type'
  | 'klaviyoListID'
  | 'klaviyoNewsletterListID'
  | 'hubSpotFormId'
  | 'hubSpotNewsletterFormId'
  | 'submit'
  | 'analytics'
  | 'strings'
> & {
  _key: string
  service: 'klaviyo' | 'hubSpot'
  successMsg: SanityContentFragment[]
  errorMsg: SanityContentFragment[]
  terms?: SanityContentFragment[]
  newsletterStatement?: SanityContentFragment[]
  buttonStyle?: SanityButtonStyle & HasFontCase
}

export type SanityIframeBlock = Pick<Iframe, '_type' | 'width' | 'height'> & {
  _key: string
  title?: string
  url?: string
}

export interface SanityTeamMember {
  name: string
  slug: string
  jobTitle?: string
  about?: string
  email?: string
  linkedInUrl?: string
  blogAuthor?: {
    slug: string
  }
  photo?: SanityImageFragment
}

export type SanityTeamMemberCardBlock = Pick<TeamMemberCard, '_type'> & {
  _key: string
  teamMember: SanityTeamMember
}

export type SanityContactFormBlock = Pick<
  ContactForm,
  '_type' | 'clickUpListId' | 'submit' | 'analytics'
> & {
  _key: string
  service: 'clickup'
  dynamicParameters?: ClickUpDynamicParameter[]
  successMsg: SanityContentFragment[]
  errorMsg: SanityContentFragment[]
  terms?: SanityContentFragment[]
  buttonStyle?: SanityButtonStyle & HasFontCase
}

export interface SanityQuoteCarouselItem {
  _key: string
  author: SanityContentFragment[]
  quote: SanityContentFragment[]
}

interface SanityQuoteCarousel {
  quotes: SanityQuoteCarouselItem[]
}

export type SanityQuoteCarouselBlock = Pick<QuoteCarousel, '_type'> &
  SanityQuoteCarousel & {
    _key: string
  }

type SanityBaseBlocks =
  | SanityFreeformBlock
  | SanityAccordionsBlock
  | SanityProductCardBlock
  | SanitySignupFormBlock
  | SanityLoginFormBlock
  | SanityPasswordRecoveryFormBlock
  | SanityAccountDetailsBlock
  | SanityAccountAddressDetailsBlock
  | SanityAccountProductListBlock
  | SanityAccountOrderListBlock
  | SanityVideoBlock
  | SanityCollectionCarouselBlock
  | SanityNewsletterBlock
  | SanityProductBundleFormBlock
  | SanityBlogPostCardBlock
  | SanityContentCarouselBlock
  | SanityCalendlyWidgetBlock
  | SanityCookieDeclarationBlock
  | SanityDemoFormBlock
  | SanityIframeBlock
  | SanityTeamMemberCardBlock
  | SanityContactFormBlock
  | SanityQuoteCarouselBlock

interface SanityPredefinedContentBlock {
  contentBlocks: SanityBaseBlocks[]
}

export type SanityPredefinedContentBlockBlock = Pick<
  PredefinedContentBlock,
  '_type'
> &
  SanityPredefinedContentBlock & { _key: string }

export type SanityBlocks = SanityBaseBlocks | SanityPredefinedContentBlockBlock
